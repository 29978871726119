import React, { useContext, useState } from 'react'
import {
  Col,
  Container,
  Image,
  Row,
  Card,
  Modal,
  CardDeck,
  CardGroup,
  Form,
} from "react-bootstrap";
import { Button } from "@material-ui/core";
import { AppContext } from "../../context/AppContext"
import axios from 'axios';
import dotenv from "dotenv"
import { PortraitSharp } from '@material-ui/icons';
import { Component } from 'react';
import { render } from '@testing-library/react';
dotenv.config()

export default class CreateIPO extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      position: "",
      pdf: "",
      video: "",
      img: "",
      status: "",
      upcoming: [],
      ongoing: [],
      recent: []
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.convertBase64 = this.convertBase64.bind(this)


  }


  componentWillReceiveProps(props) {
    
    this.state = {
      upcoming: props.upcoming,
      ongoing: props.ongoing,
      recent: props.ongoing
    }
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })


  }
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await this.convertBase64(file);

    this.setState({ img: base64 })
  };
  async handleSubmit(e) {
    e.preventDefault()
    const {upcoming,ongoing,recent, ...ipo}=this.state
    if(this.state.status === "upcoming"){
      
    
        for ( var i=this.state.position-1;i<upcoming.length;i++){
          this.props.Updateposition(upcoming[i].id,upcoming[i].position+1)
        }
      
    
    }
    if(this.state.status === "ongoing"){
      for ( var i=this.state.position-1;i<ongoing.length;i++){
        this.props.Updateposition(ongoing[i].id,ongoing[i].position+1)
      }
    }

    if(this.state.status === "recent"){
      for ( var i=this.state.position-1;i<recent.length;i++){
        this.props.Updateposition(recent[i].id,recent[i].position+1)
      }
    }
    console.log(ipo)
    try {
      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/ipo`, ipo, {
        headers: {
          'Authorization': `Bearer ${this.context.user.token}`
        }
      })
      this.props.fetchIPO()
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
      }
    }

    this.props.onHide();



  }
  render() {
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Create IPO</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <Form style={{ alignItems: "left" }} onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label>Name of the Company</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name of the Company"
                required
                name="name"
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label>Position</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter position"
                required
                name="position"
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label>PDF link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PDF Drive link"
                required
                name="pdf"
                onChange={this.handleInputChange}

              />
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label>Video link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Youtube Video link"
                required
                name="video"
                onChange={this.handleInputChange}

              />
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: "left" }}>
              <Form.Label>Company Logo</Form.Label>
              <Form.Control
                type="file"
                placeholder="Upload Company Logo"
                accept="image/*"
                onChange={(e) => {
                  this.uploadImage(e);
                }}
                id="imgBox"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" style={{ textAlign: "left" }} className="form-radios" required >
              <div className="create-radio">
                <Form.Check type="radio" id="upcoming" name="status" value="upcoming" onChange={this.handleInputChange} />
                <Form.Label>Upcoming</Form.Label>
              </div>
              <div>
                <Form.Check type="radio" id="ongoing" name="status" value="ongoing" onChange={this.handleInputChange} />
                <Form.Label>Ongoing</Form.Label>
              </div>
              <div>
                <Form.Check type="radio" id="recent" name="status" value="recent" onChange={this.handleInputChange} />
                <Form.Label>Recent</Form.Label>
              </div>
            </Form.Group>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

    )

  }

}

// export default function CreateIPO(props) {
//   const { user } = useContext(AppContext)
//   const [ipo, setIpo] = useState({ name: "", position: "", pdf: "", video: "", img: "", status: "" });

//   const handleSubmit = async (e) => {
//     e.preventDefault();


//     try {
//       const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/ipo`, ipo, {
//         headers: {
//           'Authorization': `Bearer ${user.token}`
//         }
//       })
//       props.fetchIPO()
//     } catch (error) {
//       if (error.response) {
//         console.log(error.response.data)
//       }
//     }

//     props.onHide();
//   }


//   const handleInputChange = (e) => {

//     setIpo({ ...ipo, [e.target.name]: e.target.value })
//   }

//   const uploadImage = async (e) => {
//     const file = e.target.files[0];
//     const base64 = await convertBase64(file);
//     setIpo({ ...ipo, img: base64 })
//   };

//   const convertBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.readAsDataURL(file);

//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };

//       fileReader.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   return (
//     <Modal
//       {...props}
//       size="md"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">Create IPO</Modal.Title>
//       </Modal.Header>
//       <Modal.Body style={{ textAlign: "center" }}>
//         <Form style={{ alignItems: "left" }} onSubmit={handleSubmit}>
//           <Form.Group className="mb-3" style={{ textAlign: "left" }}>
//             <Form.Label>Name of the Company</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter Name of the Company"
//               required
//               name="name"
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//           <Form.Group className="mb-3" style={{ textAlign: "left" }}>
//             <Form.Label>Position</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter position"
//               required
//               name="position"
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//           <Form.Group className="mb-3" style={{ textAlign: "left" }}>
//             <Form.Label>PDF link</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter PDF Drive link"
//               required
//               name="pdf"
//               onChange={handleInputChange}

//             />
//           </Form.Group>
//           <Form.Group className="mb-3" style={{ textAlign: "left" }}>
//             <Form.Label>Video link</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter Youtube Video link"
//               required
//               name="video"
//               onChange={handleInputChange}

//             />
//           </Form.Group>
//           <Form.Group className="mb-3" style={{ textAlign: "left" }}>
//             <Form.Label>Company Logo</Form.Label>
//             <Form.Control
//               type="file"
//               placeholder="Upload Company Logo"
//               accept="image/*"
//               onChange={(e) => {
//                 uploadImage(e);
//               }}
//               id="imgBox"
//               required
//             />
//           </Form.Group>

//           <Form.Group className="mb-3" style={{ textAlign: "left" }} className="form-radios" required >
//             <div className="create-radio">
//               <Form.Check type="radio" id="upcoming" name="status" value="upcoming" onChange={handleInputChange} />
//               <Form.Label>Upcoming</Form.Label>
//             </div>
//             <div>
//               <Form.Check type="radio" id="ongoing" name="status" value="ongoing" onChange={handleInputChange} />
//               <Form.Label>Ongoing</Form.Label>
//             </div>
//             <div>
//               <Form.Check type="radio" id="recent" name="status" value="recent" onChange={handleInputChange} />
//               <Form.Label>Recent</Form.Label>
//             </div>
//           </Form.Group>
//           <Button variant="contained" color="primary" type="submit">
//             Submit
//           </Button>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   )
// }
