import Landing from "./components/Landing";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Marque from "./components/Marque/Marque";
import "./App.css";
import { Switch, Route, useHistory } from "react-router-dom";
import IPO from "./components/IPO/IPO";
import Latest from "./components/Latest/Latest";
import Updatelatest from "./components/Latest/Updatelatest";
import Login from "./components/login/Login";
import PrivateRoute from "./PrivateRoute";
import { useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "./context/AppContext";
import NewAdvisor from "./components/Advisor/NewAdvisor";
import Webinar from "./components/Webinar/Webinar";
import Users from "./components/users/Users";
import BajajRates from "./components/FixedDeposit/bajaj/index";
import ShriramTable from "./components/FixedDeposit/Shriram/SRates";
import CarInsurance from "./components/insurance/car/index";
import TwoWheelerInsurance from "./components/insurance/bike/index";
import HealthInsurance from "./components/insurance/health/index";
import LifeInsurance from "./components/insurance/life/index";

import Ncd from "./components/products/ncd";
import NcdTable from "./components/products/ncd/table";

import MutualFunds from "./components/products/mutualFunds/index";
import P2P from "./components/products/p2p/index";
import Bonds from "./components/products/bonds/index";
import Gsheets from "./components/products";
import BondDetails from "./components/bondDetails/bondTables";
import wealthManagement from "./components/products/wealthManagement/index";

import Career from "./components/career";
import EditJob from "./components/career/EditJob";
import ViewJob from "./components/career/ViewJob";
import Partners from "./components/partners";
import CarLoan from "./components/products/carloan";
import DigitalMarketing from "./components/DigitalMarketing";

function App() {
	const history = useHistory();
	const { user } = useContext(AppContext);

	useEffect(() => {
		// logout();
		console.log("hello");
	}, []);

	const logout = async () => {
		console.log("logout");
		try {
			const res = await axios.get(`${process.env.REACT_APP_APIURL}/api/token`, {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			});
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					console.log("error");
					localStorage.removeItem("user");
					history.push("/login");
				}
			}
		}
	};

	return (
		<div className="App">
			<ToastContainer
				position="bottom-center"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
				pauseOnHover
			/>
			<Switch>
				<Route exact path="/login" component={Login} />

				{/* <PrivateRoute exact path="/" component={Landing} /> */}
				<PrivateRoute exact path="/" component={IPO} />

				<PrivateRoute exact path="/Latest" component={Latest} />
				<PrivateRoute exact path="/bajaj" component={BajajRates} />
				<PrivateRoute exact path="/shriram" component={ShriramTable} />
				<Route exact path="/Marque" component={Marque} />

				<PrivateRoute exact path="/Latest/:id" component={Updatelatest} />

				{/* <PrivateRoute exact path="/CarForm" component={CarInsuranceTable} /> 
				<PrivateRoute exact path="/HealthForm" component={HealthInsuranceTable} />
				<PrivateRoute exact path="/BikeForm" component={BikeInsuranceTable} />  */}

				<PrivateRoute exact path="/Webinar" component={Webinar} />
				<PrivateRoute exact path="/users" component={Users} />

				<PrivateRoute exact path="/Advisor" component={NewAdvisor} />
				<PrivateRoute exact path="/insurance/car" component={CarInsurance} />
				<PrivateRoute
					exact
					path="/insurance/bike"
					component={TwoWheelerInsurance}
				/>
				<PrivateRoute
					exact
					path="/insurance/health"
					component={HealthInsurance}
				/>
				<PrivateRoute exact path="/insurance/life" component={LifeInsurance} />

				<PrivateRoute exact path="/products/ncd" component={Ncd} />
				<PrivateRoute exact path="/products/bonds" component={Bonds} />
				<PrivateRoute
					exact
					path="/products/mutual-funds"
					component={MutualFunds}
				/>
				<PrivateRoute
					exact
					path="/products/wealth-management"
					component={wealthManagement}
				/>
				<PrivateRoute exact path="/products/p2p" component={P2P} />
				<PrivateRoute exact path="/products/car-loan" component={CarLoan} />
				<PrivateRoute exact path="/partners" component={Partners} />

				<PrivateRoute exact path="/products/ncd/table" component={NcdTable} />
				<PrivateRoute
					exact
					path="/products/bonds/table"
					component={BondDetails}
				/>

				<PrivateRoute exact path="/career" component={Career} />
				<PrivateRoute exact path="/career/create-job" component={EditJob} />
				<PrivateRoute
					exact
					path="/career/view-job/:jobId"
					component={ViewJob}
				/>
				<PrivateRoute
					exact
					path="/career/edit-job/:jobId"
					component={EditJob}
				/>
				<PrivateRoute exact path="/sheet" component={Gsheets} />

				<PrivateRoute
					exact
					path="/digital-marketing"
					component={DigitalMarketing}
				/>
				{/* <PrivateRoute exact path="/products/ncd/user" component={NcdUserDetailsView} /> */}
			</Switch>
		</div>
	);
}

export default App;
